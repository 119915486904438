import {Component, Input, OnChanges, QueryList, SimpleChanges, ViewChildren} from '@angular/core';
import {D3eService} from '../../../../services/d3e-service';
import {Observable} from 'rxjs';
import {IDevice} from '../../../../types/subscription';
import {map} from 'rxjs/operators';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MatPaginator} from '@angular/material/paginator';
import {PageEvent} from '@angular/material/paginator';
import {IEndpointsResponse} from '../../../../types/endpoints';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'app-sub-endpoint-list',
  templateUrl: './sub-endpoint-list.component.html',
  styleUrls: ['./sub-endpoint-list.component.scss']
})
export class SubEndpointListComponent implements OnChanges {
  @Input() subscriptionId: number;

  endpointsResponse$: Observable<IEndpointsResponse>;

  columnsToDisplay = ['computerName', 'lastSeen'];

  pageSize = 5;
  pageIndex = 0;

  constructor(private readonly d3eService: D3eService) {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getEndpoints(changes.subscriptionId.currentValue, this.pageSize, 0);
  }

  getEndpoints(subscriptionId: number, pageSize: number, pageIndex: number) {
    const pageStart = pageIndex * pageSize;
    const query = {
      where: {
        sub_id: subscriptionId
      },
      sorts: [{
        attr: "computer_name",
        desc: false,
      }],
      pageSize: pageSize,
      pageStart: pageStart,
    };
    this.endpointsResponse$ = this.d3eService.get_home_devices(query);
  }

  handlePageEvent(pageEvent: PageEvent) {
    this.pageIndex = pageEvent.pageIndex;
    this.getEndpoints(this.subscriptionId, pageEvent.pageSize, pageEvent.pageIndex);
  }
}
