

<mat-card appearance="raised">
    <mat-card-header>
        <mat-card-title>
            <app-title-status [enabled]="networkDeceptionSensor?.enabled || fileDeceptionSensor?.enabled">
                {{ 'DECEPTION' | translate}}
            </app-title-status>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="table-wrapper">
            <table>
                <tr>
                    <th>{{'DECEPTION_TYPE' | translate}}</th>
                    <th>{{'VALUE' | translate}}</th>
                </tr>
                <tr>
                    <td>{{'FILE' | translate}}</td>
                    <td>
                        <ng-container *ngIf="deceptionFiles?.length > 0; else noDeceptionFiles">
                            <div *ngFor="let filename of deceptionFiles; let last = last">
                                <span>{{filename}}</span><span *ngIf="!last">,</span>
                            </div>
                        </ng-container>
                        <ng-template #noDeceptionFiles>
                            <span>-</span>
                        </ng-template>
                    </td>
                </tr>
                <tr>
                    <td>{{'NETWORK' | translate}}</td>
                    <td>
                        <ng-container *ngIf="ports?.length > 0; else noDeceptionUrls">
                            <ng-container *ngFor="let port of ports; let last = last">
                                <span>{{port}}</span><span *ngIf="!last">, </span>
                            </ng-container>
                        </ng-container>
                        <ng-template #noDeceptionUrls>
                            <span>-</span>
                        </ng-template>
                    </td>
                </tr>
            </table>
        </div>
    </mat-card-content>
</mat-card>

<mat-card appearance="raised">
    <mat-card-header>
        <mat-card-title>
            <app-title-status [enabled]="trustedNetwork?.enabled">
                {{ 'D3ED_NET' | translate}}
            </app-title-status>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="table-wrapper">
            <span *ngIf="!networks?.length; else networksTable">{{'D3ED_NO_NETWORKS' | translate}}</span>
            <ng-template #networksTable>
                <mat-table [dataSource]="networks">
                    <ng-container matColumnDef="icon">
                        <th scope="col" mat-header-cell *matHeaderCellDef><span class="table-th-header">{{'D3ED_STATUS' | translate}}</span></th>
                        <td mat-cell *matCellDef="let network">
                        <span class="wifi"
                              [ngClass]="{'trusted-wifi': network.online && state?.enabled, 'untrusted-wifi': !network.online || !state?.enabled}">
                            <span class="mdi mdi-wifi" aria-hidden="true"></span>
                        </span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th scope="col" mat-header-cell *matHeaderCellDef><span class="table-th-header">{{'NAME' | translate}}</span></th>
                        <td mat-cell [ngClass]="{'disabled-text': !state?.enabled}" *matCellDef="let network">
                            {{ network.name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="trusted">
                        <th scope="col" mat-header-cell *matHeaderCellDef>{{'D3ED_TRUSTED' | translate}}</th>
                        <td mat-cell class="center-text" [ngClass]="{'disabled-text': !state?.enabled}" *matCellDef="let network">
                            <span *ngIf="network.network_state === 2">Yes</span>
                            <span *ngIf="network.network_state !== 2" [ngClass]="{'disabled-text': !state?.enabled}">No</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="firstConnected">
                        <th scope="col" mat-header-cell *matHeaderCellDef>{{'D3ED_FIRST_CON' | translate}}</th>
                        <td mat-cell [ngClass]="{'disabled-text': !state?.enabled}" *matCellDef="let network">
                            {{ network.firstConnected * 1000 | date }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="ipv4Address">
                        <th scope="col" mat-header-cell *matHeaderCellDef>{{'D3ED_IPV4' | translate}}</th>
                        <td mat-cell class="center-text" [ngClass]="{'disabled-text': !state?.enabled}" *matCellDef="let network">
                            {{ network.ipv4Address }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="gateway_mac">
                        <th scope="col" mat-header-cell *matHeaderCellDef>{{'D3ED_MAC' | translate}}</th>
                        <td mat-cell class="center-text" [ngClass]="{'disabled-text': !state?.enabled}" *matCellDef="let network">
                            {{ network.gateway_mac }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="networkColumns"></tr>
                    <tr mat-row *matRowDef="let myRowData; columns: networkColumns"></tr>
                </mat-table>
            </ng-template>
        </div>
    </mat-card-content>
</mat-card>

<mat-card appearance="raised">
    <mat-card-header>
        <mat-card-title>
            <app-title-status [enabled]="removableStorage?.enabled">
                {{ 'REMOVABLE_STORAGE' | translate}}
            </app-title-status>
        </mat-card-title>
    </mat-card-header>
</mat-card>

<mat-card appearance="raised">
    <mat-card-header>
        <mat-card-title>
            <app-title-status [enabled]="avTetherSensor?.enabled">
                {{ 'ANTIVIRUS_TETHERING' | translate}}
            </app-title-status>
        </mat-card-title>
    </mat-card-header>
</mat-card>
