import {Component, Input} from '@angular/core';
import {ISubscription, LicenseType} from '../../../../types/subscription';
import { BehaviorSubject } from 'rxjs';
import { UserService } from '../../../../services/user-service';
import { OrgType } from '../../../../types/group';

@Component({
  selector: 'app-subscription-heading',
  templateUrl: './subscription-heading.component.html',
  styleUrls: ['./subscription-heading.component.scss']
})
export class SubscriptionHeadingComponent {
  @Input() subscription: ISubscription;
  protected readonly LicenseType = LicenseType;
  public readonly DATE_UNLIMITED = new Date(2050, 0, 1);
  isMSP$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private readonly userService: UserService) {
          this.userService.subscribeToUser().subscribe(user => {
              if (user?.org_type.value === OrgType.MSP) {
                this.isMSP$.next(true);
              }
          })
  }

  isUnlimited(exp_timestamp: string) {
    return (new Date(exp_timestamp)) >= this.DATE_UNLIMITED;
  }
}
