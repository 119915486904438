<ng-container>
    <div *ngIf="subscriptionDataLoaded">
        <span class="subscription-data-loaded"></span>
    </div>
    <h1 class="centered-banner" *ngIf="!(isCustomerSelected$ | async); else customerSelected">
        {{'products.select-a-customer' | translate}}
    </h1>
    <ng-template #customerSelected>
        <ng-container *ngIf="subscriptions$ | async as subscriptions">
            <h1 class="centered-banner" *ngIf="subscriptions.length === 0">
                {{'products.no-products' | translate}}
            </h1>
            <ng-container *ngFor="let subscription of subscriptions">
                <ng-container [ngSwitch]="subscription.stripe_product.stripe_product_id">
                    <app-bitlocker-sub *ngSwitchCase="BITLOCKER_PRODUCT_PLAN_ID" [subscription]="subscription"></app-bitlocker-sub>
                    <app-hardware-sub *ngSwitchCase="HARDWARE_PRODUCT_PLAN_ID" [subscription]="subscription"></app-hardware-sub>
                    <app-hardware-sub *ngSwitchCase="_HARDWARE2_PRODUCT_PLAN_ID" [subscription]="subscription"></app-hardware-sub>
                    <app-data-defense-sub *ngSwitchDefault [subscription]="subscription"></app-data-defense-sub>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-template>
</ng-container>

