<div class="heading">
    <mat-card appearance="raised">
        <div>
            <div>{{'products.licenses-assigned' | translate}}</div>
            <div>{{subscription.used+subscription.pba_used}}/{{subscription.count}}</div>
        </div>
        <div *ngIf="(isMSP$ | async)">
            <div>{{'products.pba-extended-features' | translate}}</div>
            <div>{{ subscription.enable_pba_extended_features === true ? ('ENABLED' | translate) : ('DISABLED' | translate) }}</div>
        </div>
        <div *ngIf="(isMSP$ | async)">
            <div>{{'products.pba-cmd-log' | translate}}</div>
            <div>{{ subscription.enable_pba_log_erase === true ? ('ENABLED' | translate) : ('DISABLED' | translate) }}</div>
        </div>

    </mat-card>
    <mat-card appearance="raised">
        <div>
            <div>{{'products.sub-type' | translate}}</div>
            <ng-container [ngSwitch]="subscription.type">
                <ng-container *ngSwitchCase="LicenseType.LICENSE_TYPE_TIME_LIMITED">
                    <div>{{'TIME_LIMITED' | translate}}</div>
                </ng-container>
                <ng-container *ngSwitchCase="LicenseType.LICENSE_TYPE_FREE">
                    <div>{{'D3E_FREE' | translate}}</div>
                </ng-container>
                <ng-container *ngSwitchCase="LicenseType.LICENSE_TYPE_PERPETUAL">
                    <div>{{'PERPETUAL' | translate}}</div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <div>{{'unknown' | translate}}</div>
                </ng-container>
            </ng-container>
        </div>
        <div>
            <div>{{'products.sub-expiration' | translate}}</div>
            <ng-container *ngIf="isUnlimited(subscription.exp_timestamp); else haveExpTimestamp">
                <div>{{'UNLIMITED' | translate}}</div>
            </ng-container>
            <ng-template #haveExpTimestamp>
                <div>{{subscription.exp_timestamp | date}}</div>
            </ng-template>
        </div>
        <div>
            <div>{{'products.sub-id' | translate}}</div>
            <div><span id="subscription_id">{{subscription.subscription_id}}</span></div>
        </div>
    </mat-card>
    <mat-card appearance="raised">
        <a [routerLink]="['/downloads']" [queryParams]="{subscriptionId: subscription.subscription_id}">{{'products.download-software' | translate}}</a>
    </mat-card>
</div>
