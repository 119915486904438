<div class="endpoint-activity-wrapper">
    <div class="activity-component-header">
        <p class="component-label">
          <span class="draggable">{{'dashboard.endpoint-activity.title' | translate}}</span>
          <a [routerLink]="['/events']" [queryParams]="getHeaderQueryParams()">
            <span class="mdi mdi-open-in-new open-icon link"></span>
          </a>
        </p>
        <span class="activity-toggle-button-group">
          <mat-button-toggle-group hideSingleSelectionIndicator="true" [value]="allActivities" name="activity-filter" (change)="onActivityToggleChanged($event)"
                                   [disabled]="selectedActivityFilter === -1 && activityData.length == 0" aria-label="Show all activity or filter by user or device">
            <mat-button-toggle [value]="allActivities">All</mat-button-toggle>
            <mat-button-toggle [value]="ActivityType.User"><span class="mdi mdi-account-outline"></span></mat-button-toggle>
            <mat-button-toggle [value]="ActivityType.Device"><span class="mdi mdi-monitor"></span></mat-button-toggle>
          </mat-button-toggle-group>
        </span>
    </div>
    <div *ngIf="!loadingEvents; else skeletonLoader" class="activity-content" id="endpoint-activity-content">
        <div class="activity-table-wrapper" id="endpoint-activity-table">
            <span *ngIf="activityData.length == 0" class="no-activity">{{'dashboard.endpoint-activity.no-data' | translate}}</span>
            <table *ngIf="activityData.length > 0" class="activity-table" mat-table [dataSource]="activityData">
                <ng-container matColumnDef="icon">
                    <td mat-cell *matCellDef="let item">
                        <span *ngIf="item.type == ActivityType.Device" class="mdi mdi-monitor activity-icon"></span>
                        <span *ngIf="item.type == ActivityType.User" class="mdi mdi-account-outline activity-icon"></span>
                    </td>
                </ng-container>
                <ng-container matColumnDef="text">
                    <td mat-cell *matCellDef="let item">
                      <span *ngIf="item.uuid === null; else link">
                        {{item.text}}
                      </span>
                      <ng-template #link>
                        <a [routerLink]="['/device', item.uuid]">{{item.text}}</a>
                      </ng-template>
                    </td>
                </ng-container>
                <ng-container matColumnDef="when">
                    <td mat-cell class="when" *matCellDef="let item"> {{item.when}} </td>
                </ng-container>
                <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
            </table>
        </div>
    </div>
    <ng-template #skeletonLoader>
      <ngx-skeleton-loader
        count="7"
        appearance="line"
        [theme]="{
          margin: '0 10px',
          height: '42px',
          width: '97%',
        }">
      </ngx-skeleton-loader>
    </ng-template>
</div>
