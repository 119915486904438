<div  class="endpoint-activity-wrapper">
    <div class="activity-component-header">
        <p class="component-label">
          <span class="draggable">{{'dashboard.decryption-activity.title' | translate}}</span>
          <a [routerLink]="['/encryption']">
            <span class="mdi mdi-open-in-new open-icon link"></span>
          </a>
        </p>
    </div>
    <div *ngIf="!loading; else skeletonLoader" class="activity-content" id="decryption-activity-content">
        <div class="activity-table-wrapper" id="endpoint-activity-table" >
                <span *ngIf="this.selectedOrgType == OrgType.CUSTOMER &&
                             this.activityData.length == 0 &&
                             this.loading == false" class="no-activity">{{'dashboard.decryption-activity.no-data' | translate}}</span>
                <span *ngIf="this.selectedOrgType !== OrgType.CUSTOMER" class="no-activity">{{'dashboard.select-a-customer' | translate}}</span>
                <table *ngIf="this.activityData.length > 0 || this.loading === true" class="activity-table" mat-table [dataSource]="activityData">
                    <ng-container matColumnDef="icon">
                        <th scope="col" mat-header-cell *matHeaderCellDef>{{'dashboard.decryption-activity.icon'}}</th>
                        <td mat-cell *matCellDef="let item">
                            <span *ngIf="item.type == ActivityType.Encrypt" class="mdi mdi-zip-box activity-icon"></span>
                            <span *ngIf="item.type == ActivityType.Decrypt" class="mdi mdi-zip-box-outline activity-icon"></span>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="text">
                        <th scope="col" mat-header-cell *matHeaderCellDef matSortDisabled>{{'dashboard.endpoint-activity.text'}}</th>
                        <td mat-cell *matCellDef="let item"> {{item.text}} </td>
                    </ng-container>
                    <ng-container matColumnDef="when">
                        <th scope="col" mat-header-cell *matHeaderCellDef matSortDisabled>{{'dashboard.endpoint-activity.when'}}</th>
                        <td mat-cell *matCellDef="let item" class="when"> {{item.when}} </td>
                    </ng-container>
                    <!--<tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>-->
                    <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
                </table>
            </div>
    </div>
    <ng-template #skeletonLoader>
      <ngx-skeleton-loader
        count="7"
        appearance="line"
        [theme]="{
          margin: '0 10px',
          height: '42px',
          width: '97%',
        }">
      </ngx-skeleton-loader>
    </ng-template>
</div>
