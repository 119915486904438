<div class="wrapper">
    <h1>BitLocker</h1>
    <app-subscription-heading [subscription]="subscription"></app-subscription-heading>
    <mat-card appearance="raised">
        Included Software
        <table mat-table [dataSource]="data">
            <ng-container matColumnDef="productName">
                <th mat-header-cell *matHeaderCellDef>{{'products.product-name' | translate}}</th>
                <td mat-cell *matCellDef="let software">
                    <span *ngIf="software.hasEndpoints; else justTheName">
                            <a [routerLink]="['/endpoints']" [queryParams]="{endpoints_target:'inventory'}">{{software.productName}}</a>
                    </span>
                    <ng-template #justTheName>
                        <span>{{software.productName}}</span>
                    </ng-template>
                </td>

            </ng-container>

            <ng-container matColumnDef="assignedLicenses">
                <th mat-header-cell *matHeaderCellDef>{{'products.assigned-licenses' | translate}}</th>
                <td mat-cell *matCellDef="let software">
                    <span *ngIf="software.licensesApply; else doesNotApply">
                        {{software.assignedLicenses}}
                    </span>
                    <ng-template #doesNotApply>
                        <span>{{'products.does-not-apply' | translate}}</span>
                    </ng-template>
                </td>
            </ng-container>

            <ng-container matColumnDef="purchasedQuantity">
                <th mat-header-cell *matHeaderCellDef>{{'products.purchased-quantity' | translate}}</th>
                <td mat-cell *matCellDef="let software">{{software.purchasedQuantity}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
        </table>
    </mat-card>

    <mat-card *ngIf="subscription.used > 0" appearance="raised">
        <app-sub-endpoint-list [subscriptionId]="subscription.id"></app-sub-endpoint-list>
    </mat-card>
</div>
