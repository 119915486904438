<ng-container *ngrxLet="endpointsResponse$ | async as endpointsResponse">
    <div class="table-wrapper">
        <table mat-table [dataSource]="endpointsResponse?.devices" >
            <ng-container matColumnDef="computerName">
                <th mat-header-cell *matHeaderCellDef>{{'COMPUTER_NAME' | translate}}</th>
                <td mat-cell *matCellDef="let endpoint">
                    <a [routerLink]="['/device', endpoint.uuid]">{{endpoint.computer_name}}</a>
                </td>
            </ng-container>

            <ng-container matColumnDef="lastSeen">
                <th mat-header-cell *matHeaderCellDef>{{'D3ED_ACTIVATED' | translate}}</th>
                <td mat-cell *matCellDef="let endpoint">{{endpoint.created_dttm  | date}}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
            <tr mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></tr>
        </table>
    </div>
    <mat-paginator [pageSize]="pageSize"
                   [pageSizeOptions]="[5, 25, 50]"
                   [pageIndex]="pageIndex"
                   [hidePageSize]="true"
                   [length]="endpointsResponse?.total"
                   (page)="handlePageEvent($event)"></mat-paginator>
</ng-container>

