import { Component, Input, OnInit } from '@angular/core';
import { ISubscription } from '../../../../types/subscription';
import { IIncludedSoftware } from '../products.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.state';
import { combineLatest, Observable } from 'rxjs';
import { IManageUser, UserRole } from '../../../../types/user';
import { selectUser } from '../../../store/user/user.selectors';
import { Partners } from '../../../../types/partners';
import { selectOrganizations } from '../../../store/organizations/organizations.selectors';
import { HeaderService } from '../../../header.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-data-defense-sub',
  templateUrl: './data-defense-sub.component.html',
  styleUrls: ['./data-defense-sub.component.scss']
})
export class DataDefenseSubComponent implements OnInit  {
  @Input() subscription: ISubscription;
  columnsToDisplay = ['productName', 'assignedLicenses', 'purchasedQuantity'];
  user$: Observable<IManageUser> = this.store.select(selectUser());
  private data: IIncludedSoftware[] = [];

  data$: Observable<IIncludedSoftware[]> = combineLatest([
      this.user$,
      this.store.select(selectOrganizations()),
      this.headerService.orgSelectedChanged,
    ]).pipe(
      map(([user, orgs, selectedOrgId]) => {
          return this.data.filter(d => {
            const orgId: number = !!selectedOrgId && selectedOrgId !== 'All' ? +selectedOrgId : user.group_id;
            const orgPartnerId = orgs.find(o => o.id === orgId).partner_id;
            const partnerId = orgPartnerId !== orgId ? orgPartnerId : user.partnerId
            return !d.hiddenForPartners.includes(partnerId);
          })
      })
    );

  constructor(private readonly store: Store<AppState>,
              private readonly headerService: HeaderService) {}

  ngOnInit() {
      this.data = [
        {
          productName: "Cigent CLI",
          assignedLicenses: 0,
          licensesApply: false,
          canAssign: false,
          hasEndpoints: false,
          purchasedQuantity: this.subscription.count,
          hiddenForPartners: [Partners.Lenovo],
        },
        {
          productName: "PBA Licenses",
          assignedLicenses: this.subscription.pba_used,
          licensesApply: true,
          canAssign: true,
          hasEndpoints: false,
          purchasedQuantity: this.subscription.count,
          hiddenForPartners: [Partners.Lenovo],
        },
        {
          productName: "Data Defense",
          assignedLicenses: this.subscription.used,
          licensesApply: true,
          canAssign: false,
          hasEndpoints: true,
          purchasedQuantity: this.subscription.count,
          hiddenForPartners: [],
        },
      ];
  }

  protected readonly UserRole = UserRole;
}
