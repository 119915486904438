import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HeaderService } from '../../../header.service';
import { TranslateService } from '@ngx-translate/core';
import { BaseWidget } from 'gridstack/dist/angular';
import { Observable, Subscription, timer } from 'rxjs';
import { SelectedOrgMethod } from '../../../../shared/selectedOrg';
import { SortAttr } from '../../../../types/common';
import { EncryptionEventService } from '../../../../services/encryption-event.service';
import { EncryptionEvent } from '../../../../types/encryption-events';
import { DashboardUtils } from '../dashboard-util';
import { AppComponent } from '../../../app.component';
import { IGroup, OrgType } from '../../../../types/group';
import { UserService } from '../../../../services/user-service';
import { IUser } from '../../../../types/user';

enum ActivityType {
  Encrypt = 0,
  Decrypt = 1
}

interface ActivityData {
  type: ActivityType;
  text: string;
  when: string; // of seconds ago
}


@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-decryption-activity',
  templateUrl: './decryption-activity.component.html',
  styleUrls: ['./decryption-activity.component.scss', '../dynamic-dashboard.common.scss']
})
export class DecryptionActivityComponent extends BaseWidget implements OnInit, OnDestroy {

    selectedOrgId: string;
    organizations: IGroup[];
    selectedOrgType: OrgType = OrgType.CUSTOMER;
    user: IUser;

    protected readonly ActivityType = ActivityType;
    activityData: ActivityData[] = [];
    columnsToDisplay: string[] = ['icon', 'text', 'when' ];

    pageSize = 300;

    loading = true;

    timerTrigger$: Observable<number> = timer(0, 60000);
    refreshSub: Subscription;

    daysAgo = 'Days Ago'
    hoursAgo = 'Hours Ago'
    minsAgo = 'Minutes Ago'
    secsAgo = 'Seconds Ago'

    queryPageStart = 0;
    queryPageSize = 300;
    sortAttrs: [SortAttr] = [{ 'sortAttr': 'dttm', 'sortOrder': 'desc' }];
    // 2 is decryption events, from encryption_events.ts
    // 3 file key added
    // 4 File Key removed
    // 5 is key used
    events: string [] = ['2', '3', '4', '5'];
    search: string = null;
    daysFilter: number = 14;
    scopes = ['user', 'subscription']; // not including recovery key

    constructor(private headerService: HeaderService,
                private translate: TranslateService,
                private encryptionEventService: EncryptionEventService,
                private appComponent: AppComponent,
                private userService: UserService) {
        super();
        
    this.translate.get(['dashboard.ago_days', 'dashboard.ago_hours',
        'dashboard.ago_minutes', 'dashboard.ago_seconds'])
        .subscribe(vals => {
            this.daysAgo = vals['dashboard.ago_days'];
            this.hoursAgo = vals['dashboard.ago_hours'];
            this.minsAgo = vals['dashboard.ago_minutes'];
            this.secsAgo = vals['dashboard.ago_seconds'];
        });


    }

    ngOnInit(): void {
        this.headerService.orgSelectedChanged
          .pipe(untilDestroyed(this))
          .subscribe(value => {
            this.selectedOrgId = value;
            if ( this.organizations != null ) {
                this.setSelectedOrgType();
            } else {
                this.selectedOrgType = OrgType.CUSTOMER;
            }
            this.startTimer();
        });

        this.appComponent.organizations$.subscribe(orgs => {
            this.organizations = orgs;
            this.selectedOrgType = OrgType.CUSTOMER;
            if ( this.selectedOrgId != null ) {
                this.setSelectedOrgType();
            }
        })

        this.userService.getUser().subscribe(user => {
            this.user = user
            if (this.selectedOrgId != null) {
                this.setSelectedOrgType();
            }
        });
        this.startTimer();
    }

    private setSelectedOrgType() {
        this.selectedOrgType = OrgType.CUSTOMER;
        if (this.user) {
            let orgId = this.user.group_id;
            if (this.selectedOrgId != 'All') {
                orgId = +this.selectedOrgId;
            }
            this.organizations.forEach(org => {
                if (org.id == orgId) {
                    this.selectedOrgType = org.org_type;
                }
            })
        }
    }

    ngOnDestroy(): void {
      this.refreshSub.unsubscribe();
    }

    startTimer() {
      this.loading = true;
      this.activityData = [];
      if ( this.refreshSub ) {
          this.refreshSub.unsubscribe()
      }
      this.refreshSub = this.timerTrigger$.subscribe(() => {
            this.getActivity();
      });
   }

    private getActivity() {
        let timeLow = DashboardUtils.getSeconds(this.daysFilter);
        this.loading = true;
        const selectedOrg = SelectedOrgMethod.getSelectedOrg(this.selectedOrgId);
        this.encryptionEventService.getEncryptionEvents(selectedOrg, this.pageSize, null,
            this.search, this.events, this.sortAttrs, this.scopes, timeLow)
        .subscribe(response => {
            let events = response.events;
            const newActivityData = []
            this.loading = false;
            var secondsNow = DashboardUtils.getSeconds();

            if ( response.events && response.events.length > 0 ) {
                response.events.forEach( event => {
                    let activityData = this.translateEvent(event, secondsNow)
                    if ( activityData != null) {
                        newActivityData.push(activityData)
                    }
                })
            }
            this.activityData = newActivityData

        });
    }

    private translateEvent(event: EncryptionEvent, secondsNow: number) {

        let activityData = null;
        let eventDate = Date.parse(event.dttm)
        let eventDttm = eventDate / 1000;
        const whenSeconds = (secondsNow - eventDttm);
        const when = DashboardUtils.getWhen(whenSeconds, this.daysAgo, this.hoursAgo, this.minsAgo, this.secsAgo);

        let item: ActivityData = {
          type: ActivityType.Decrypt,
          text: '',
          when: when
        }
        let scope = '';
        if ( event.scope != null ) {
            scope = this.translate.instant('encryption-events-viewer.key-scope.' + event.scope)
        }
        const data = {
            username: event.email,
            computer_name: event.computerName,
            filename: event.fileName,
            scope: scope
        }
        switch ( event.eventType ) {
            case 2:
                item.text = this.translate.instant('dashboard.decryption-activity.console_decrypt', data)
                activityData = item;
                break;
            case 3:
                item.text = this.translate.instant('dashboard.decryption-activity.file_key_added', data)
                activityData = item;
                break;
            case 4:
                item.text = this.translate.instant('dashboard.decryption-activity.file_key_removed', data)
                activityData = item;
                break;
            case 5:
                item.text = this.translate.instant('dashboard.decryption-activity.key_used', data)
                activityData = item;
                break;
            default:
                // do not process this event
                break;
        }
        return activityData;
    }

    getWhen(seconds: number): string {
      if ( Math.floor(seconds / 86400) > 0 ) {
            return Math.floor(seconds / 86400) + ' ' + this.daysAgo;
      }
      if ( Math.floor(seconds / 3600) > 0 ) {
            return Math.floor(seconds / 3600) + ' ' + this.hoursAgo;
      }
      if ( Math.floor(seconds / 60) > 0 ) {
            return Math.floor(seconds / 60) + ' ' + this.minsAgo;
      }
      return Math.floor(seconds) + ' ' + this.secsAgo;
    }

    protected readonly OrgType = OrgType;
}
